// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angela-js": () => import("./../../../src/pages/angela.js" /* webpackChunkName: "component---src-pages-angela-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-hackillinois-js": () => import("./../../../src/pages/blog/hackillinois.js" /* webpackChunkName: "component---src-pages-blog-hackillinois-js" */),
  "component---src-pages-blog-wireframes-en-js": () => import("./../../../src/pages/blog/wireframes.en.js" /* webpackChunkName: "component---src-pages-blog-wireframes-en-js" */),
  "component---src-pages-blog-wireframes-zh-js": () => import("./../../../src/pages/blog/wireframes.zh.js" /* webpackChunkName: "component---src-pages-blog-wireframes-zh-js" */),
  "component---src-pages-blog-zh-js": () => import("./../../../src/pages/blog.zh.js" /* webpackChunkName: "component---src-pages-blog-zh-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-zh-js": () => import("./../../../src/pages/privacy.zh.js" /* webpackChunkName: "component---src-pages-privacy-zh-js" */),
  "component---src-pages-work-en-js": () => import("./../../../src/pages/work.en.js" /* webpackChunkName: "component---src-pages-work-en-js" */),
  "component---src-pages-work-one-js": () => import("./../../../src/pages/work/one.js" /* webpackChunkName: "component---src-pages-work-one-js" */),
  "component---src-pages-work-one-zh-js": () => import("./../../../src/pages/work/one.zh.js" /* webpackChunkName: "component---src-pages-work-one-zh-js" */),
  "component---src-pages-work-zh-js": () => import("./../../../src/pages/work.zh.js" /* webpackChunkName: "component---src-pages-work-zh-js" */)
}

