import "./src/styles/global.scss"

// import i18next from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18next.use(LanguageDetector).init({
//   detection: {
//     // order and from where user language should be detected
//     order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  
//     // keys or params to lookup language from
//     lookupQuerystring: 'lng',
//     lookupCookie: 'i18next',
//     lookupLocalStorage: 'i18nextLng',
//     lookupSessionStorage: 'i18nextLng',
//     lookupFromPathIndex: 0,
//     lookupFromSubdomainIndex: 0,
  
//     // cache user language on
//     caches: ['localStorage', 'cookie'],
//     excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  
//     // optional expire and domain for set cookie
//     cookieMinutes: 10,
//     cookieDomain: 'myDomain',
  
//     // optional htmlTag with lang attribute, the default is:
//     htmlTag: document.documentElement,
  
//     // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
//     cookieOptions: { path: '/', sameSite: 'strict' }
//   },
// });

// // console.log("bro")

// i18next.on('languageChanged', function(lng) {
//   console.log(lng)
//   window.location.href = `${window.location.origin}/${lng}${window.location.pathname}`;
// });